import { Component, OnInit } from '@angular/core';

import { Project } from '../../models/project'; //modelo
import { ProjectService } from '../../services/project.service'; //servicio


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  providers:[ProjectService]
})
export class AboutComponent implements OnInit {

  public title:string;
  public subtitle:string;
  public projects:Project[];
  public url:string;
  //public contentAbout;

  constructor(
    private _projectService:ProjectService
  ) {
    this.title="Sobre mi";
    this.subtitle='¡Hola, soy Diego Vasconcelo!';
    //this.contentAbout='Básicamente soy un nerd apasionado por la  tecnología, unas de mis metas es poder ayudar a las personas creando soluciones que mejoren su día a día. Mientras cursaba la carrera Analista de Sistema de Computación, empece a fascinarme por la programación, y me di cuenta que podría  cumplir mis metas creando software.';
  }

  ngOnInit(){
    
  }
  

}
