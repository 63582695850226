import { Component, OnInit } from '@angular/core';
import { Project } from '../../models/project'; //modelo
import { ProjectService } from '../../services/project.service'; //servicio
import { MockProjects } from '../../services/global'//Mock de la API
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
  providers:[ProjectService]
})
export class DetailComponent implements OnInit {
  public url: string;
  public project: Project[];

  constructor(
    private _projectService: ProjectService,
    private _router: Router,
    private _route: ActivatedRoute
  ){
    this.project = MockProjects;
  }

  ngOnInit(){
    this._route.params.subscribe(params=>{
      let id=params.id

      this.getProject(id)
    });
  }

  getProject(id){
    this.project = this.project.filter(project =>project._id == id);
    
    if (this.project.length <= 0) {
      this._router.navigate(['/error']);
    }

    return this.project;    

  }
}


