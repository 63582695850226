import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  public title:string
  public subtitle:string
  public twitter:string;
  public linkedin:string;
  public github:string; 
  public email:string;
  public emailBody:string;


  constructor() {
    this.title="¡Hablemos!";
    this.subtitle="Aquí dejo los medios por donde podrás ponerte en contacto conmigo";
    this.email="diegovasconcelo@icloud.com";
    this.emailBody="👋 Gracias por tomarte unos minutos y contactarme 🚀";
    //Social Networtks
    this.twitter="https://twitter.com/D_Vasconcelo";
    this.linkedin="https://www.linkedin.com/in/diegovasconcelo/";
    this.github="https://github.com/diegovasconcelo";
   }

  ngOnInit(): void {
  }

}
