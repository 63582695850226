import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public title:String;
  public subtitle:String;

  public twitter:string;
  public linkedin:string;
  public github:string; 
  
  constructor() { 
    this.title="Diego Vasconcelo";
    this.subtitle="Analista de sistema de computación & Desarrollador de Software";
    //Social Networtks
    this.twitter="https://twitter.com/D_Vasconcelo";
    this.linkedin="https://www.linkedin.com/in/diegovasconcelo/";
    this.github="https://github.com/diegovasconcelo";
  }

  ngOnInit(): void {
  }

}
