<div class="row">
    <div class="col-12" id="title">
        <h1 class="text-center">{{title}}</h1>
        <h2 class="text-center">{{subtitle}}</h2>
    </div>
</div>
<article class="home">
    <div class="skills">
        <h3>SKILLS</h3>
        <div class="backend">
            <h4>BACKEND</h4>
            <div class="octagon octa-1">
                <h2>Docker</h2>
            </div>
            <div class="octagon octa-2">
                <h2>MySQL</h2>
            </div>
            <div class="octagon octa-3">
                <h2>PostgreSQL</h2>
            </div>
            <div class="octagon octa-4">
                <h2>MongoDB</h2>
            </div>
            <div class="octagon octa-5">
                <h2>NodeJS</h2>
            </div>
            <div class="octagon octa-6">
                <h2>ExpressJS</h2>
            </div>
            <div class="octagon octa-7">
                <h2>Python</h2>
            </div>
            <div class="octagon octa-8">
                <h2>Django</h2>
            </div>
        </div>

        <div class="frontend">
            <h4>FRONTEND</h4>
            <div class="octagon octa-1">
                <h2>HTML</h2>
            </div>
            <div class="octagon octa-2">
                <h2>CSS</h2>
            </div>
            <div class="octagon octa-3">
                <h2>JS-TS</h2>
            </div>
            <div class="octagon octa-4">
                <h2>React</h2>
            </div>
            <div class="octagon octa-5">
                <h2>Angular</h2>
            </div>
            <div class="octagon octa-6">
                <h2>Bootstrap</h2>
            </div>
        </div>
    </div>

    <div class="social-network">
        <a href="{{github}}" target="_blank" class="m-2">GitHub</a>
        <a href="{{linkedin}}" target="_blank" class="m-2">LinkedIn</a>
        <a href="{{twitter}}" target="_blank" class="m-2">Twitter</a>
    </div>
</article>