    <div class="row">
        <div class="col-12" id="title">
            <h1 class="text-center">{{title}}</h1>
            <h2 class="text-center">{{subtitle}}</h2>
        </div>
    </div>

    <div class="row align-self-center">
        <div class="clearfix col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"></div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <img src="../../../assets/dvu.png" class="d-none d-sm-block img-fluid" alt="Diego Vasconcelo Analista de Sistema de Computacion Desarrollardor Web Programador"/>
        </div>
        <div class="clearfix col-4 col-sm-4  col-md-4 col-lg-4 col-xl-4"></div>
    </div>
    
    <div class="row">
        <article class="about col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
            <p class="text-left">
                Un apasionado de la ciencia y la tecnología, me motiva cómo podemos usarla para mejorar la vida de las personas. Desde que descubrí la programación, entendí que tenía en mis manos una herramienta capaz de transformar el mundo. Mi meta siempre ha sido crear soluciones que vayan más allá de lo innovador: que realmente marquen la diferencia en el día a día de las personas.
            </p>
            <h4 class="bullets">👨🏼‍🎓 Mi camino en la tecnología</h4>
            <p class="text-left">
                Me formé como Analista de Sistemas de Computación, donde adquirí una base sólida en lógica y estructuras de datos. Pero lo que realmente despertó mi curiosidad fue la posibilidad de dotar a las máquinas de autonomía, no solo programarlas para seguir instrucciones, sino encontrar formas de que "aprendan" y tomen decisiones por sí mismas.                       
                Esa inquietud me llevó a explorar la inteligencia artificial y robótica (mucho antes de que se convirtieran en tendencia), comenzando con algoritmos clásicos de machine learning y deep learning. Hoy sigo ampliando y formalizando esos conocimientos mientras realizo una licenciatura en inteligencia artificial y robótica.
            </p>
            <h4 class="bullets">👨🏼‍💻 Lo que hago hoy</h4>
            <p class="text-left">
                Con cerca de cinco años de experiencia en desarrollo de software, me especializo en backend, NLP y arquitectura en la nube. Trabajo integrando modelos de I.A. en aplicaciones reales, desarrollando API’s eficientes y explorando nuevas formas de optimizar procesos con machine learning. También me dedico a la investigación y evaluación de modelos de lenguaje (LLM), analizando constantemente qué modelos se adaptan mejor a cada necesidad.
            </p>
            <h4 class="bullets">🚀 ¿Qué sigue?</h4>
            <p class="text-left">
                Seguir aprendiendo, experimentando y construyendo. Pienso que la tecnología tiene el potencial de cambiar el mundo, pero depende de cómo la usemos. Mi objetivo es seguir impulsando el desarrollo de soluciones que acerquen la I.A. a la vida cotidiana, haciendo que esta tecnología sea más práctica, accesible y transformadora.
            </p>
            <h4 class="bullets">📩 Hablemos</h4>
            <p class="text-left">
                Si tienes un proyecto interesante o simplemente quieres compartir ideas sobre I.A., tecnología o desarrollo, <a [routerLink]="['/contacto']">¡contáctame!</a> Siempre disfruto de una buena charla sobre este maravilloso mundo.
            </p>
                
            </article>
    </div>