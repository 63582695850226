<html lang="es">


  <head>
    <title>Diego Vasconcelo</title>
    <meta charset="utf-8">
   </head>

  <div class="container">

    <div class="row">
      <section class="col-12" id="content">

        <nav class="navbar navbar-expand-md navbar-dark">
          <header class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <a class="navbar-brand pl-3 pt-3" [routerLink]="['/']" id="logo">DVU</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span id="toggler-icon">|||</span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="" [routerLink]="['/sobre-mi']" [routerLinkActive]="['actived']">Sobre mi</a>
                </li>
                <li class="nav-item">
                  <a class="pl-3" [routerLink]="['/proyectos']" [routerLinkActive]="['actived']">Proyectos</a>
                </li>
                <li class="nav-item">
                  <a class="pl-3" [routerLink]="['/contacto']" [routerLinkActive]="['actived']">Contacto</a>
                </li>
              </ul>
            </div> 
          </header>
        </nav>

          <router-outlet></router-outlet>
        
      </section>

    </div> 

    <footer class="footer">
      Desarrollado con <span class="heart">&#x2665;</span> por Diego Vasconcelo
    </footer>
    
  </div>
</html>