import { Component, OnInit } from '@angular/core';
import { Project } from '../../models/project'; //modelo
import { ProjectService } from '../../services/project.service'; //servicio
import { MockProjects } from '../../services/global'//Mock de la API

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  providers:[ProjectService]
})
export class ProjectsComponent implements OnInit {
  public projects:Project[];
  public url:string;

  constructor(){ 
    this.projects = MockProjects;
  }

  ngOnInit() {
    this.getProjects();
  }

  getProjects(){
    return this.projects
  }
}
