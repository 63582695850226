
<div class="row">
    <div class="col-12" id="title">
        <h1 class="text-center">{{title}}</h1>
        <h2 class="text-center">{{subtitle}}</h2>
    </div>
</div>

<div id="contact" class="row">
    <div class="col-12">
        <h4 id="email" class="text-center">
            Puedes hacerlo a través de mi
            <i class="social-network"><a href="mailto:{{email}}? &body={{emailBody}}">Correo</a></i>
        </h4>
    </div>
</div>

<div id="contact" class="row">
    <div class="col-12 social-network">
        <h4 class="text-center">También estoy en</h4>
        <a href="{{github}}" target="_blank" class="m-2">GitHub</a>
        <a href="{{linkedin}}" target="_blank" class="m-2">LinkedIn</a>
        <a href="{{twitter}}" target="_blank" class="m-2">Twitter</a>
    </div>
</div>