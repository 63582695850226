    <div class="row">
        <div class="col-12" id="title">
            <h1 class="text-center">{{title}}</h1>
            <h2 class="text-center">{{subtitle}}</h2>
        </div>
    </div>

    <div class="row align-self-center">
        <div class="clearfix col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"></div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <img src="../../../assets/dvu.png" class="d-none d-sm-block img-fluid" alt="Diego Vasconcelo Analista de Sistema de Computacion Desarrollardor Web Programador"/>
        </div>
        <div class="clearfix col-4 col-sm-4  col-md-4 col-lg-4 col-xl-4"></div>
    </div>
    
    <div class="row">
        <article class="about col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
            <p class="text-left">Básicamente, un nerd apasionado por la ciencia y tecnología, unas de mis metas es poder ayudar a las personas creando soluciones que mejoren su día a día.<br/> 

                <br/>Mientras cursaba la carrera de analista comencé a fascinarme por la programación ya que nos permite transformar el entorno que nos rodea.<br/> 
                
                <br/>Uno de los proyectos más complejos que he podido desarrollar es <a [routerLink]="['/proyecto/'+ '60079fa2c3a9c413bd07b215']">gTruck</a>, el cual es una aplicación web para la gestión de flotas de camiones. 
                    Este software cuenta con una sección de mantenimiento preventivo, de esta manera se evitan roturas en los equipos de transporte, ademas genera informes dinámicos que sirven de apoyo para la toma de decisiones.<br/> 
                
                <br/>Actualmente sigo investigando y formándome en diversas tecnologías, esto me permite tener una visión más amplia a la hora de tener que crear soluciones.<br/> 
                
                <br/>Si quieres que te ayude con un proyecto o tienes algo que compartirme no dudes en <a [routerLink]="['/contacto']">contactarme</a>, será todo un placer.
            </p>
        </article>
    </div>












