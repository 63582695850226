<div class="row">
    <div class="col-12" id="title">
        <h1 class="text-center">Proyectos</h1>
    </div>

    <div class="col-12">
        <div class="row">
            <div *ngFor="let project of projects" class="project col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card-group">
                    <div class="card h-100 bg-dark m-2">
                        <div class="overflow">
                            <img class="card-img-top" src="../../../assets/uploads/{{project.image}}" *ngIf="project.image" alt="Imagen del proyecto {{project.description}}">
                        </div>
                        <a class="row"[routerLink]="['/proyecto', project._id]">
                            <div class="card-body">
                                <h5 class="card-title text-center"> {{project.name}} </h5>
                                <p class="card-text text-center"> {{project.category}} </p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>