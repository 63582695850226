<div class="row">
  <div class="col-12 col-md-8">
      <svg  viewBox="0 0 837 1045">
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
              <path d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z" id="Polygon-1" stroke="#28A2BF" stroke-width="6" sketch:type="MSShapeGroup"></path>
              <path d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z" id="Polygon-2" stroke="#38C6BA" stroke-width="6" sketch:type="MSShapeGroup"></path>
              <path d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z" id="Polygon-3" stroke="#B82AAF" stroke-width="6" sketch:type="MSShapeGroup"></path>
          </g>
      </svg>
    </div> 

  <div class="col-12 col-md-4 align-self-center">
    <div class="message-box" >
      <h1 class="text-center">404</h1>
      <p class="text-center">¡OOPS, página no encontrada!</p>
    </div>
  </div>
</div>



