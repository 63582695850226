<div class="row" *ngIf="project">
    <div id="title" class="col-12">
        <h1 class="text-center">Proyecto: {{project[0].name}}</h1>
        <h2 class="text-center">características</h2>
    </div>

    <div class="row" *ngIf="project[0]">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <img class="img-fluid rounded" src="../../../assets/uploads/{{project[0].image}}" *ngIf="project[0].image" alt="{{project[0].name}} {{project[0].category}} tecnologías {{project[0].technologies}}"/>
        </div>

        <div class="col-12">
            <div class="projectDescription">
                <p class="text-center">
                    <span>Categoría:</span>    
                    {{project[0].category}}
                </p>
                <p class="text-center"><span>Descripción:</span> 
                    {{project[0].description}}
                 </p>
                <p class="text-center">
                    <span>Tecnologías:</span> 
                    {{project[0].technologies}}
                </p>
                <p class="text-center">
                    <a href="{{project[0].url}}" target="_blank">
                        <span>URL</span> 
                    </a>
                </p>
                <p class="text-center">
                    <span>Año:</span>
                    {{project[0].year}}
                </p>
                
                <hr/>

            </div>
        </div>
    </div>
</div>




