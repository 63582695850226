import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ProjectService{
    public url:String;

    constructor(
        private _http:HttpClient
    ){}
    
    getProjects():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'aplication/json');

        return this._http.get(this.url+'projects',{headers:headers});
    }

    getProject(id):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','aplication,json');

        return this._http.get(this.url+'project/'+id,{headers:headers});
    }


}
