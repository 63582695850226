export var Global={
    url:'http://localhost:3700/api/'
};

export const MockProjects = [
    {
        "_id": "6139koijxdt12s145we3d5d8g",
        "name": "Vocalia",
        "description": "Asistente de Texto a Voz – transforma sin esfuerzo el texto en audio de alta calidad.",
        "category": "Aplicación Web",
        "year": 2023,
        "technologies": "Python, Streamlit, Docker",
        "url": "https://vocalia.cloud.dvutech.io/",
        "image": "Vop-z-Lf7nFy4B8E9.png"
    },
    {
        "_id": "6042aceefaf5c01a8e1fa7d4",
        "name": "DVU",
        "description": "Single Page Application de tipo portfolio.",
        "category": "SPA",
        "year": 2021,
        "technologies": "MongoDB, ExpressJS, Angular, NodeJS",
        "url": "/",
        "image": "HTc-E-kfZ2334vxCZdLBKmCH.png"
    },
    {
        "_id": "6043faf98310600eb58149e0",
        "name": "The Nerd Shop",
        "description": "TNS es una tienda geek virtual que posee productos solo para nerd.",
        "category": "Aplicación Web",
        "year": 2021,
        "technologies": "MySQL, PHP, HTML, CSS, JS",
        "url": "https://github.com/diegovasconcelo/TheNerdShop",
        "image": "I0nJVwPhmtCWQkqZyHzOQCRv.png"
    },
    {
        "_id": "608e17df8310600eb58149e1",
        "name": "inBlog",
        "description": "Es un proyecto open source que te permite crear diferentes secciones dentro de la página y un blog con categorías y artículos. Puedes ver una demo dando clic en \"URL\".",
        "category": "Aplicación Web",
        "year": 2021,
        "technologies": "SQLite, Python, Django, HTML, CSS, JS",
        "url": "https://dvuinblog.herokuapp.com",
        "image": "ipiy5gi5J85dM5k8_MlixurY.png"
    },
    {
        "_id": "60ef28448310600eb58149e2",
        "name": "FusionAI",
        "description": "Es una fusión de diferentes contenidos relacionados a la Inteligencia Artificial. Los artículos se han traducido al español para que haya más contenido del mundo de la IA en ese idioma. Puedes ver una demo dando clic en \"URL\".",
        "category": "Aplicación Web",
        "year": 2021,
        "technologies": "Docker, PostgreSQL, Python, Django, HTML, CSS, JS",
        "url": "https://dvufusionai.herokuapp.com",
        "image": "95ResuCdem8JLtfNAI8nV3Us.jpeg"
    },
    {
        "_id": "6042ab65faf5c01a8e1fa7d3",
        "name": "DVU Notes",
        "description": "Es un proyecto que permite compartir e intercambiar notas de manera rápida y sencilla o pudes usarlo como un blog si así lo deseas.",
        "category": "Aplicación web",
        "year": 2020,
        "technologies": "MySQL, PHP, HTML, CSS",
        "url": "https://github.com/diegovasconcelo/BlogPHP",
        "image": "70NVQFXF8FNDAYn2XeWr8EwN.png"
    },
    {
        "_id": "60079fa2c3a9c413bd07b215",
        "name": "gTruck",
        "description": "Sistema de gestión para flotas de camiones.",
        "category": "Aplicación web",
        "year": 2020,
        "technologies": "MySQL, PHP, HTML, CSS, JS, Bootstrap",
        "url": "https://gtruck.me/",
        "image": "8tKTeJIhlltdcNrtXkpovVL2.png"
    },
    {
        "_id": "6042aecdfaf5c01a8e1fa7d6",
        "name": "Note Assistant",
        "description": "Asistente de notas que utiliza la interfaz de línea de comandos.",
        "category": "CLI",
        "year": 2020,
        "technologies": "MySQL; Python",
        "url": "https://github.com/diegovasconcelo/noteAssistantPython",
        "image": "ANJzUfUExAS2-0A3S6nCwHj5.png"
    }
]